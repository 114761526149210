import React, { Component, RefObject } from 'react';
import './AlbumInfo.global.scss';

type FontStyle = { font?: string; fontSize?: string };

export default class AlbumInfo extends Component<AlbumInfoProps> {
  ref: RefObject<HTMLDivElement>;
  constructor(props: AlbumInfoProps) {
    super(props);
    this.ref = React.createRef();
    this.showLayout1Hover = this.showLayout1Hover.bind(this);
  }
  componentDidMount() {
    this.props.styleParams.forceHover && setTimeout(this.showLayout1Hover, 200);
  }

  componentDidUpdate(prevProps: AlbumInfoProps) {
    if (this.props.styleParams.layout !== prevProps.styleParams.layout) {
      if (this.props.styleParams.layout === 1) {
        setTimeout(this.showLayout1Hover, 200);
      }
      if (prevProps.styleParams.layout === 1 && this.ref.current) {
        this.ref.current.style.bottom = '';
      }
    }
  }

  showLayout1Hover() {
    if (this.props.styleParams.layout === 1 && this.ref.current) {
      this.ref.current.style.bottom = '0';
    }
  }

  getPresetFontIfNeeded(
    type: string,
    textObj: any,
    styleParams: any,
    textPresets: any,
  ) {
    if (styleParams[type + 'Font']) {
      return null;
    } else {
      const textSizeType = textObj[type];
      const font = textPresets[textSizeType];
      return font.value.substring(5, font.value.length - 1);
    }
  }

  getTextsObject(width: number, textPresets: any, styleParams: any) {
    const detailsText = [
      // < 160 no font
      { title: 'Body-M', date: 'Body-S', count: 'Body-S' }, // 160 - 280
      { title: 'Body-L', date: 'Body-M', count: 'Body-S' }, // 281 - 700
      { title: 'Heading-S', date: 'Body-L', count: 'Body-S' }, // 701 - 980
      { title: 'Heading-M', date: 'Body-L', count: 'Body-M' }, // > 980
    ];
    if (!textPresets) {
      return;
    }

    let idx,
      albumClass = '';
    if (width < 160) {
      // no text
      idx = -1;
      albumClass = 'none';
    } else if (width < 180) {
      // 160-180
      idx = 0;
      albumClass = 'small';
    } else if (width < 220) {
      // 180-220
      idx = 0;
      albumClass = 'small2';
    } else if (width < 280) {
      // 220-280
      idx = 0;
      albumClass = 'small3';
    } else if (width < 340) {
      // 280-340
      idx = 1;
      albumClass = 'small3';
    } else if (width < 440) {
      // 340 - 440
      albumClass = 'medium';
      idx = 1;
    } else if (width < 700) {
      // 440 -700
      albumClass = 'big';
      idx = 1;
    } else if (width < 980) {
      // 700 - 980
      albumClass = 'xl';
      idx = 2;
    } else {
      // > 980
      albumClass = 'xxl';
      idx = 3;
    }

    if (idx < 0) {
      return { albumClass };
    }

    const textObj = detailsText[idx];

    return {
      title: this.getPresetFontIfNeeded(
        'title',
        textObj,
        styleParams,
        textPresets,
      ),
      titleSize: textPresets[textObj.title].size,
      date: this.getPresetFontIfNeeded(
        'date',
        textObj,
        styleParams,
        textPresets,
      ),
      dateSize: textPresets[textObj.date].size,
      countSize: textPresets[textObj.count].size,
      albumClass,
    };
  }

  createDetailsInner(
    texts: TextsObject,
    album: GogAlbum,
    layout: number,
    translate: any,
  ) {
    let detailsInner;
    if (texts) {
      const titleStyle: FontStyle = {};
      if (texts.title) {
        titleStyle.font = texts.title;
      }
      titleStyle.fontSize = texts.titleSize;

      const dateStyle: FontStyle = {},
        countStyle: FontStyle = {};
      if (texts.date) {
        dateStyle.font = texts.date;
        dateStyle.fontSize = texts.dateSize;
        countStyle.font = texts.date; // same as dat
        countStyle.fontSize = texts.countSize;
      }

      let title, date;
      if (album.editables.title) {
        title = (
          <div className="title" style={titleStyle}>
            {album.editables.title}
          </div>
        );
      } else {
        title = (
          <div className="title" style={titleStyle}>
            &nbsp;
          </div>
        );
      }

      if (album.editables.date) {
        date = (
          <div className="date" style={dateStyle}>
            {album.editables.date}&nbsp;
          </div>
        );
      } else {
        date = (
          <div className="date" style={dateStyle}>
            &nbsp;
          </div>
        );
      }

      if (layout === 4) {
        const photosCount = (
          <div className="photos-count" style={dateStyle}>
            {album.photosCount}{' '}
            {(translate &&
              translate('Dashboard_Main_Album_Details_NumPhotos')) ||
              ' photos'}
          </div>
        );
        detailsInner = [date, title, photosCount];
      } else {
        detailsInner = [title, date];
      }
    } else {
      detailsInner = <div></div>;
    }

    return detailsInner;
  }

  render() {
    const texts = this.getTextsObject(
      this.props.dimensions.width,
      this.props.textPresets,
      this.props.styleParams,
    ) as TextsObject;

    const translate = typeof this.props.t === 'function' && this.props.t;
    const layout = this.props.styleParams.layout;

    const album = this.props.data;

    const detailsInner = this.createDetailsInner(
      texts,
      album,
      layout,
      translate,
    );

    const borderRgba = 'black';

    const textAlign = this.props.styleParams.textAlignment || 'center';

    const albumStyle = {
      width: this.props.dimensions.width + 'px',
      marginRight: '',
      marginTop: '',
      borderWidth: '1px',
      borderColor: borderRgba,
      height:
        layout === 1 || layout === 4 ? this.props.dimensions.width + 'px' : '',
      position: layout === 4 ? 'absolute' : undefined,
      bottom: layout === 4 ? '0' : undefined,
    } as React.CSSProperties;

    if (layout === 1 || layout === 4) {
      albumStyle.height = albumStyle.width;
    }

    const albumClassName = ['album', 'layout' + layout];
    if (texts) {
      const textAlbumClass = texts.albumClass || '';
      albumClassName.push(textAlbumClass);
    }
    const extIcon =
      'albums-svg-font-icons-' +
      (album.settings && album.settings.isPublic === false
        ? 'lock'
        : 'external');

    return (
      <div className={albumClassName.join(' ')} style={albumStyle}>
        {layout === 4 && (
          <div
            className="overlay"
            style={{ opacity: this.props.styleParams.forceHover ? '1' : '' }}
          >
            <div className="mask"></div>
            <i className={extIcon}></i>
          </div>
        )}
        <div ref={this.ref} className="details-container">
          <div
            className={'details' + (!texts ? ' hide' : '')}
            style={{ textAlign }}
          >
            {detailsInner}
          </div>
        </div>
      </div>
    );
  }
}
