import { GALLERY_CONSTS } from 'pro-gallery';

export const parseViewMode = (viewMode: string) => {
  switch (viewMode && viewMode.toLowerCase()) {
    case 'editor':
      return GALLERY_CONSTS.viewMode.EDIT;
    case 'preview':
      return GALLERY_CONSTS.viewMode.PREVIEW;
    case 'site':
    default:
      return GALLERY_CONSTS.viewMode.SITE;
  }
};

export const parseFormFactor = (formFactor: FormFactor) => {
  switch (formFactor.toLowerCase()) {
    case 'tablet':
      return GALLERY_CONSTS.formFactor.TABLET;
    case 'mobile':
      return GALLERY_CONSTS.formFactor.MOBILE;
    case 'desktop':
    default:
      return GALLERY_CONSTS.formFactor.DESKTOP;
  }
};
