import React from 'react';
import ReactDOM from 'react-dom';
import s from './AlbumModal.scss';

type ModalProps = {
  isOpen: boolean;
  link: string;
  closeModal: any;
  isRelocateGogIframeEnabled: boolean;
};
export default class AlbumModal extends React.Component<ModalProps> {
  // constructor(props: ModalProps) {
  //   super(props);`
  // }
  closeButtonRef: any;

  componentDidUpdate(prevProps: any) {
    if (!prevProps.isOpen && this.props.isOpen) {
      setTimeout(() => this.closeButtonRef?.focus(), 0);
    }
  }

  render() {
    const iframeElement = (
      <div className={s.modalBackground}>
        <button
          tabIndex={1}
          ref={(ref) => (this.closeButtonRef = ref)}
          id="closeButton"
          className={s.closeButton}
          onClick={() => {
            this.props.closeModal();
          }}
        >
          <svg viewBox="0 0 180 180">
            <path d="M5 5 L175 175 M175 5 L5 175" />
          </svg>
        </button>
        <button
          tabIndex={2}
          className={s.externalButton}
          onClick={() => window.open(this.props.link)}
        />
        <div
          className={
            this.props.isRelocateGogIframeEnabled
              ? s.relocateIframe
              : s.holdsTheIframe
          }
        >
          <iframe
            tabIndex={3}
            title="Album Modal"
            id="albumFrame"
            className={s.iframe}
            src={this.props.link}
          ></iframe>
        </div>
      </div>
    );
    if (this.props.isOpen && this.props.link) {
      return ReactDOM.createPortal(iframeElement, document.body);
    } else {
      return <div />;
    }
  }
}
