import { RenderUtils } from '@wix/photography-client-lib';
import { GALLERY_CONSTS } from 'pro-gallery';

export class Utils extends RenderUtils {
  /* @ngInject */

  static parseViewMode(viewMode) {
    switch (viewMode.toLowerCase()) {
      case 'editor':
        return GALLERY_CONSTS.viewMode.EDIT;
      case 'preview':
        return GALLERY_CONSTS.viewMode.PREVIEW;
      case 'site':
      default:
        return GALLERY_CONSTS.viewMode.SITE;
    }
  }

  static parseFormFactor(formFactor) {
    switch (formFactor.toLowerCase()) {
      case 'tablet':
        return GALLERY_CONSTS.formFactor.TABLET;
      case 'mobile':
        return GALLERY_CONSTS.formFactor.MOBILE;
      case 'desktop':
      default:
        return GALLERY_CONSTS.formFactor.DESKTOP;
    }
  }
}

export const utils = new Utils();
