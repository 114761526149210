import { defaultGOGStyleParams } from './defaultStyleParams';
import _ from 'lodash';

function getTextAlignStr(textAlignValue: number) {
  let textAlignStr: string = '';
  switch (textAlignValue) {
    case 0:
      textAlignStr = 'left';
      break;
    case 1:
      textAlignStr = 'center';
      break;
    case 2:
      textAlignStr = 'right';
      break;
    default:
      break;
  }
  return textAlignStr;
}

function getInfoStyles(layout: number) {
  const infoStyles: any = {};
  infoStyles.calculateTextBoxHeightMode = 'MANUAL';
  infoStyles.textBoxHeight = 0;

  if (layout === 2) {
    infoStyles.textBoxHeight = 100;
    infoStyles.textsVerticalPadding = -15;
  }
  if (layout === 3) {
    infoStyles.textBoxHeight = 60;
    infoStyles.textsVerticalPadding = -10;
  }

  if (layout === 4) {
    infoStyles.textsVerticalPadding = -10;
    infoStyles.hoveringBehaviour = 'APPEARS';
  }
  return infoStyles;
}

export function albumsStylesToPGStyles(
  styleParams: any,
  options: { isMobile: boolean },
) {
  const gogStyles = {
    ...defaultGOGStyleParams,
    ...styleParams.numbers,
    ...styleParams.booleans,
    ...styleParams.fonts,
    ...styleParams.colors,
  };
  // pick only styles relevant to old gog
  let pgStyles: any = _.pick(
    gogStyles,
    'thumbnailSize',
    'albumPadding',
    'borderWidth',
    'borderColor',
    'detailsBgColor',
    'textAlign',
    'cropMode',
    'layout',
    'albumClick',
    'defaultColorWithOpacity',
    'dateFont',
    'titleFont',
    'infoFont',
    'dateColor',
    'titleColor',
    'infoColor',
    'forceHover',
  );
  pgStyles = {
    ...pgStyles,
    ...getInfoStyles(gogStyles.layout),
  };

  pgStyles.textAlignment = getTextAlignStr(gogStyles.textAlign);

  if (typeof gogStyles.thumbnailSize !== undefined) {
    pgStyles.numberOfImagesPerRow = [4, 3, 2, 1][gogStyles.thumbnailSize];
  }

  // if (options.isMobile) {
  //   pgStyles.numberOfImagesPerRow = 1;
  // }

  pgStyles.itemBorderWidth = gogStyles.borderWidth;
  pgStyles.itemBorderColor = gogStyles.borderColor;

  if (typeof gogStyles.albumPadding !== undefined) {
    pgStyles.galleryMargin = gogStyles.albumPadding;
    pgStyles.imageMargin = gogStyles.albumPadding;
  }

  return pgStyles;
}
