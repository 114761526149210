import { createStylesParams, StyleParamType } from '@wix/tpa-settings';

export type IStylesParams = {
  sortBy: StyleParamType.Number;
  albumClick: StyleParamType.Number;
  lastThumbnailSize: StyleParamType.Number;
  galleryLayout: StyleParamType.Number;
  gridStyle: StyleParamType.Number;
  overlayAnimation: StyleParamType.Number;
  titlePlacement: StyleParamType.Number;
  galleryHorizontalAlign: StyleParamType.Number;
  itemBorderRadius: StyleParamType.Number;
  thumbnailSize: StyleParamType.Number;
  itemBorderWidth: StyleParamType.Number;
  textBoxBorderRadius: StyleParamType.Number;
  textBoxBorderWidth: StyleParamType.Number;
  galleryMargin: StyleParamType.Number;
  imageMargin: StyleParamType.Number;
  isProGalleryGoG: StyleParamType.Boolean;
  itemEnableShadow: StyleParamType.Boolean;
  allowDescription: StyleParamType.Boolean;
  itemOpacity: StyleParamType.Color;
  itemShadowOpacityAndColor: StyleParamType.Color;
  itemBorderColor: StyleParamType.Color;
  galleryTextAlign: StyleParamType.Number;
  itemShadowBlur: StyleParamType.Number;
  itemShadowDirection: StyleParamType.Number;
  imageLoadingMode: StyleParamType.Number;
  imageLoadingWithColorMode: StyleParamType.Number;
  imageLoadingColor: StyleParamType.Color;
  itemShadowSize: StyleParamType.Number;
  numberOfImagesPerRow: StyleParamType.Number;
  itemFont: StyleParamType.Font;
  itemFontSlideshow: StyleParamType.Font;
  isVertical: StyleParamType.Number;
  itemDescriptionFontColorSlideshow: StyleParamType.Color;
  itemFontColorSlideshow: StyleParamType.Color;
  allowTitle: StyleParamType.Boolean;
  hoveringBehaviour: StyleParamType.Number;
  imageInfoType: StyleParamType.Number;
  imageHoverAnimation: StyleParamType.Number;
  itemDescriptionFontColor: StyleParamType.Color;
  itemFontColor: StyleParamType.Color;
  textBoxHeight: StyleParamType.Number;
  cubeImages: StyleParamType.Boolean;
  showArrows: StyleParamType.Boolean;
  previewHover: StyleParamType.Boolean;
  groupSize: StyleParamType.Number;
  fixedColumns: StyleParamType.Number;
  hasThumbnails: StyleParamType.Boolean;
  enableScroll: StyleParamType.Boolean;
  isGrid: StyleParamType.Boolean;
  isSlider: StyleParamType.Boolean;
  isMasonry: StyleParamType.Boolean;
  isColumns: StyleParamType.Boolean;
  isSlideshow: StyleParamType.Boolean;
  cropOnlyFill: StyleParamType.Boolean;
  scrollDirection: StyleParamType.Number;
  oneRow: StyleParamType.Boolean;
  smartCrop: StyleParamType.Boolean;
  minItemSize: StyleParamType.Number;
  cubeRatio: StyleParamType.Number;
  imageResize: StyleParamType.Number;
  placeGroupsLtr: StyleParamType.Boolean;
  floatingImages: StyleParamType.Number;
  groupTypes: StyleParamType.Number;
  sampleSize: StyleParamType.Number;
  chooseBestGroup: StyleParamType.Boolean;
  collageDensity: StyleParamType.Number;
  groupsPerStrip: StyleParamType.Number;
  at: StyleParamType.Number;
  itemIconColor: StyleParamType.Color;
  enableInfiniteScroll: StyleParamType.Number;
  loadMoreAmount: StyleParamType.Number;
  isRTL: StyleParamType.Number;
  galleryImageRatio: StyleParamType.Number;
  slideshowLoop: StyleParamType.Number;
  isAutoSlideshow: StyleParamType.Number;
  autoSlideshowInterval: StyleParamType.Number;
  imageRatioType: StyleParamType.Number;
  galleryThumbnailsAlignment: StyleParamType.Number;
  slideshowInfoSize: StyleParamType.Number;
  playButtonForAutoSlideShow: StyleParamType.Number;
  itemIconColorSlideshow: StyleParamType.Color;
  arrowsSize: StyleParamType.Number;
  arrowsColor: StyleParamType.Color;
  arrowsPosition: StyleParamType.Number;
  itemDescriptionFont: StyleParamType.Font;
  itemDescriptionFontSlideshow: StyleParamType.Font;
  galleryVerticalAlign: StyleParamType.Number;
  textBoxFillColor: StyleParamType.Color;
  loadMoreButtonFontColor: StyleParamType.Color;
  calculateTextBoxHeightMode: StyleParamType.Number;
  textImageSpace: StyleParamType.Number;
  textsVerticalPadding: StyleParamType.Number;
  textsHorizontalPadding: StyleParamType.Number;
  titleDescriptionSpace: StyleParamType.Number;
  loadMoreButtonText: StyleParamType.Font;
  loadMoreButtonFont: StyleParamType.Font;
  loadMoreButtonColor: StyleParamType.Color;
  loadMoreButtonBorderWidth: StyleParamType.Number;
  loadMoreButtonBorderColor: StyleParamType.Color;
  loadMoreButtonBorderRadius: StyleParamType.Number;
  scrollAnimation: StyleParamType.Number;
  oneColorAnimationColor: StyleParamType.Color;
  isFirstGogPgUpdate: StyleParamType.Boolean;
  textBoxBorderColor: StyleParamType.Color;
  magicLayoutSeed: StyleParamType.Number;
  layout: StyleParamType.Number;
};

export default createStylesParams<IStylesParams>({
  sortBy: {
    type: StyleParamType.Number,
  },
  isFirstGogPgUpdate: {
    type: StyleParamType.Boolean,
  },
  albumClick: {
    type: StyleParamType.Number,
  },
  lastThumbnailSize: {
    type: StyleParamType.Number,
  },
  galleryLayout: {
    type: StyleParamType.Number,
  },
  gridStyle: {
    type: StyleParamType.Number,
  },
  overlayAnimation: {
    type: StyleParamType.Number,
  },
  titlePlacement: {
    type: StyleParamType.Number,
  },
  galleryHorizontalAlign: {
    type: StyleParamType.Number,
  },
  thumbnailSize: {
    type: StyleParamType.Number,
  },
  itemBorderWidth: {
    type: StyleParamType.Number,
  },
  galleryMargin: {
    type: StyleParamType.Number,
  },
  textBoxBorderRadius: {
    type: StyleParamType.Number,
  },
  textBoxBorderWidth: {
    type: StyleParamType.Number,
  },
  imageMargin: {
    type: StyleParamType.Number,
  },
  isProGalleryGoG: {
    type: StyleParamType.Boolean,
  },
  itemEnableShadow: {
    type: StyleParamType.Boolean,
  },
  allowDescription: {
    type: StyleParamType.Boolean,
  },
  itemOpacity: {
    type: StyleParamType.Color,
  },
  itemShadowOpacityAndColor: {
    type: StyleParamType.Color,
  },
  itemBorderColor: {
    type: StyleParamType.Color,
  },
  galleryTextAlign: {
    type: StyleParamType.Number,
  },
  itemShadowBlur: {
    type: StyleParamType.Number,
  },
  itemShadowDirection: {
    type: StyleParamType.Number,
  },
  itemBorderRadius: {
    type: StyleParamType.Number,
  },
  imageLoadingMode: {
    type: StyleParamType.Number,
  },
  imageLoadingWithColorMode: {
    type: StyleParamType.Number,
  },
  imageLoadingColor: {
    type: StyleParamType.Color,
  },
  itemShadowSize: {
    type: StyleParamType.Number,
  },
  numberOfImagesPerRow: {
    type: StyleParamType.Number,
  },
  itemFont: {
    type: StyleParamType.Font,
  },
  itemFontSlideshow: {
    type: StyleParamType.Font,
  },
  isVertical: {
    type: StyleParamType.Number,
  },
  itemDescriptionFontColorSlideshow: {
    type: StyleParamType.Color,
  },
  itemFontColorSlideshow: {
    type: StyleParamType.Color,
  },
  allowTitle: {
    type: StyleParamType.Boolean,
  },
  hoveringBehaviour: {
    type: StyleParamType.Number,
  },
  imageInfoType: {
    type: StyleParamType.Number,
  },
  imageHoverAnimation: {
    type: StyleParamType.Number,
  },
  itemDescriptionFontColor: {
    type: StyleParamType.Color,
  },
  itemFontColor: {
    type: StyleParamType.Color,
  },
  textBoxHeight: {
    type: StyleParamType.Number,
  },
  cubeImages: {
    type: StyleParamType.Boolean,
  },
  showArrows: {
    type: StyleParamType.Boolean,
  },
  previewHover: {
    type: StyleParamType.Boolean,
  },
  groupSize: {
    type: StyleParamType.Number,
  },
  fixedColumns: {
    type: StyleParamType.Number,
  },
  hasThumbnails: {
    type: StyleParamType.Boolean,
  },
  enableScroll: {
    type: StyleParamType.Boolean,
  },
  isGrid: {
    type: StyleParamType.Boolean,
  },
  isSlider: {
    type: StyleParamType.Boolean,
  },
  isMasonry: {
    type: StyleParamType.Boolean,
  },
  isColumns: {
    type: StyleParamType.Boolean,
  },
  isSlideshow: {
    type: StyleParamType.Boolean,
  },
  cropOnlyFill: {
    type: StyleParamType.Boolean,
  },
  scrollDirection: {
    type: StyleParamType.Number,
  },
  oneRow: {
    type: StyleParamType.Boolean,
  },
  smartCrop: {
    type: StyleParamType.Boolean,
  },
  minItemSize: {
    type: StyleParamType.Number,
  },
  cubeRatio: {
    type: StyleParamType.Number,
  },
  imageResize: {
    type: StyleParamType.Number,
  },
  placeGroupsLtr: {
    type: StyleParamType.Boolean,
  },
  floatingImages: {
    type: StyleParamType.Number,
  },
  groupTypes: {
    type: StyleParamType.Number,
  },
  sampleSize: {
    type: StyleParamType.Number,
  },
  chooseBestGroup: {
    type: StyleParamType.Boolean,
  },
  collageDensity: {
    type: StyleParamType.Number,
  },
  groupsPerStrip: {
    type: StyleParamType.Number,
  },
  at: {
    type: StyleParamType.Number,
  },
  itemIconColor: {
    type: StyleParamType.Color,
  },
  enableInfiniteScroll: {
    type: StyleParamType.Number,
  },
  loadMoreAmount: {
    type: StyleParamType.Number,
  },
  isRTL: {
    type: StyleParamType.Number,
  },
  galleryImageRatio: {
    type: StyleParamType.Number,
  },
  slideshowLoop: {
    type: StyleParamType.Number,
  },
  isAutoSlideshow: {
    type: StyleParamType.Number,
  },
  autoSlideshowInterval: {
    type: StyleParamType.Number,
  },
  imageRatioType: {
    type: StyleParamType.Number,
  },
  galleryThumbnailsAlignment: {
    type: StyleParamType.Number,
  },
  slideshowInfoSize: {
    type: StyleParamType.Number,
  },
  playButtonForAutoSlideShow: {
    type: StyleParamType.Number,
  },
  itemIconColorSlideshow: {
    type: StyleParamType.Color,
  },
  arrowsSize: {
    type: StyleParamType.Number,
  },
  arrowsColor: {
    type: StyleParamType.Color,
  },
  arrowsPosition: {
    type: StyleParamType.Number,
  },
  itemDescriptionFont: {
    type: StyleParamType.Font,
  },
  itemDescriptionFontSlideshow: {
    type: StyleParamType.Font,
  },
  galleryVerticalAlign: {
    type: StyleParamType.Number,
  },
  textBoxFillColor: {
    type: StyleParamType.Color,
  },
  calculateTextBoxHeightMode: {
    type: StyleParamType.Number,
  },
  textImageSpace: {
    type: StyleParamType.Number,
  },
  textsVerticalPadding: {
    type: StyleParamType.Number,
  },
  textsHorizontalPadding: {
    type: StyleParamType.Number,
  },
  titleDescriptionSpace: {
    type: StyleParamType.Number,
  },
  loadMoreButtonText: {
    type: StyleParamType.Font,
  },
  loadMoreButtonFont: {
    type: StyleParamType.Font,
  },
  loadMoreButtonColor: {
    type: StyleParamType.Color,
  },
  loadMoreButtonBorderWidth: {
    type: StyleParamType.Number,
  },
  loadMoreButtonBorderColor: {
    type: StyleParamType.Color,
  },
  loadMoreButtonFontColor: {
    type: StyleParamType.Color,
  },
  loadMoreButtonBorderRadius: {
    type: StyleParamType.Number,
  },
  scrollAnimation: {
    type: StyleParamType.Number,
  },
  oneColorAnimationColor: {
    type: StyleParamType.Color,
  },
  textBoxBorderColor: {
    type: StyleParamType.Color,
  },
  magicLayoutSeed: {
    type: StyleParamType.Number,
  },
  layout: {
    type: StyleParamType.Number,
  },
});
