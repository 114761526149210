import { utils } from './webUtils';
import { window } from '@wix/photography-client-lib';
import ResizeObserver from 'resize-observer-polyfill';

export default class DimensionsHelper {
  constructor(parent, props) {
    this.container = {};
    this.domId = props.compId || '';
    this._cache = {};
    this.lastPropsJson = '';
    this.parent = parent;
    this.parentDidMount = false;
  }

  getOrPutInCache(field, createValue) {
    if (this._cache[field]) {
      return this._cache[field];
    }
    this._cache[field] = createValue();
    return this._cache[field];
  }

  dumpCache(field) {
    if (field) {
      this._cache[field] = undefined;
    } else {
      this._cache = {};
    }
  }

  update(props) {
    if (!this.parentDidMount) {
      return;
    }
    const { dimensions, id } = props;
    const propsJson = JSON.stringify({ dimensions, id });
    if (this.lastPropsJson !== propsJson) {
      this.lastPropsJson = propsJson;
      this.domId = id || this.domId;
      this.dimensions = { ...this.dimensions, ...dimensions };
      this.measureContainer();
    }
    this.observeResize();
    this.observeIntersection();
  }

  setParentDidMount() {
    this.parentDidMount = true;
  }

  createResizeObserver() {
    this.resizeObserver = new ResizeObserver(() => {
      this.measureContainer();
    });
    this.observeResize();
  }

  observeResize() {
    if (this.observingResize || !this.resizeObserver) {
      return;
    }
    const galleryWrapperElement = window.document.getElementById(
      `gallery-wrapper-${this.domId}`,
    );
    if (galleryWrapperElement) {
      this.observingResize = true;
      this.resizeObserver.observe(galleryWrapperElement);
    }
  }

  createIntersectionObserver() {
    if (utils.isSSR()) {
      return;
    }
    // import of intersection-observer breaks SSR!!!

    if (typeof window.IntersectionObserver === 'function') {
      // Export existing IntersectionObservers' implementation.
      IntersectionObserver = window.IntersectionObserver;
      this.initIntersectionObserver();
    } else {
      // Export polyfill.
      import(
        /* webpackChunkName: "intersection-observer" */ 'intersection-observer'
      ).then(() => {
        this.initIntersectionObserver();
      });
    }
  }

  initIntersectionObserver() {
    this.intersectionObserver = new IntersectionObserver((entries) => {
      const entry = entries.length > 0 && entries[0];
      if (entry && entry.boundingClientRect) {
        const newWrapperBoundingRectY = entry.boundingClientRect.y;
        if (this.wrapperBoundingRectY !== newWrapperBoundingRectY) {
          this.measureScrollBase(newWrapperBoundingRectY);
        }
      }
    });
    this.observeIntersection();
  }

  observeIntersection() {
    if (this.observingIntersection || !this.intersectionObserver) {
      return;
    }
    try {
      const galleryWrapperElement = window.document.getElementById(
        `gallery-wrapper-${this.domId}`,
      );
      if (galleryWrapperElement) {
        this.observingIntersection = true;
        this.intersectionObserver.observe(galleryWrapperElement);
      }
    } catch (e) {
      console.error('dimensionsHelper -> observeIntersection,', e);
    }
  }

  measureContainer() {
    this.dumpCache();
    this.container =
      this.fixContainerSizeIfNeeded(this.dimensions) || this.container;
    const container = {
      ...this.getGalleryDimensions(),
      ...this.getScrollBase(),
    };
    if (
      JSON.stringify(container) !== JSON.stringify(this.parent.state.container)
    ) {
      this.parent.setState({ container });
    }
  }

  measureScrollBase(newWrapperBoundingRectY) {
    this.dumpCache('scrollBase');
    this.dumpCache('bodyBoundingRect');
    const scrollBase = this.calcScrollBase(newWrapperBoundingRectY);
    if (
      JSON.stringify(scrollBase) !==
      JSON.stringify(this.parent.state.container.scrollBase)
    ) {
      const container = { ...this.parent.state.container, scrollBase };
      this.parent.setState({ container });
    }
  }

  getGalleryDimensions() {
    return this.getOrPutInCache('galleryDimensions', () => {
      const res = {
        avoidGallerySelfMeasure: this.parent.avoidGallerySelfMeasure,
        galleryWidth: Math.floor(this.getGalleryWidth()),
        galleryHeight: Math.floor(this.getGalleryHeight()),
        height: Math.floor(this.container.height),
        width: Math.floor(this.container.width),
        documentHeight: Math.floor(window.document.body.scrollHeight),
        windowWidth: Math.floor(window.innerWidth),
      };
      return res;
    });
  }

  getScrollBase() {
    return {
      scrollBase: Math.ceil(this.calcScrollBase()),
    };
  }

  isUnknownWidth(container = this.container) {
    return this.getOrPutInCache('isUnknownWidth', () => {
      // if the container width is not a number, it is fullwidth (e.g.: "", "100%", "calc(100% + -160px)")
      return (
        container &&
        String(parseInt(container.width, 10)) !== String(container.width)
      );
    });
  }

  isUnknownHeight(container = this.container) {
    return this.getOrPutInCache('isUnknownHeight', () => {
      // if the container height is not a number, it is fullHeight (e.g.: "", "100%", "calc(100% + -160px)")
      return (
        container &&
        String(parseInt(container.height, 10)) !== String(container.height)
      );
    });
  }

  fixContainerSizeIfNeeded(dimensions) {
    const isUnknownWidth = this.isUnknownWidth(dimensions);
    const isUnknownHeight = this.isUnknownHeight(dimensions);
    const _dimensions = { ...dimensions };
    if (isUnknownWidth) {
      _dimensions.width = this.getBoundingRect().width;
    }
    if (isUnknownHeight) {
      _dimensions.height = this.getBoundingRect().height;
    }
    return _dimensions;
  }

  calcBoundingRect() {
    if (utils.isVerbose()) {
      console.count('calcBoundingRect');
    }
    try {
      this.galleryWrapperElement = window.document.getElementById(
        `gallery-wrapper-${this.domId}`,
      );
      return this.galleryWrapperElement.getBoundingClientRect();
    } catch (e) {
      return false;
    }
  }

  getBoundingRect() {
    return this.getOrPutInCache('boundingRect', () => {
      return (
        this.calcBoundingRect() || {
          x: 0,
          y: 0,
          width: Math.floor(window.innerWidth),
          height: 0,
        }
      );
    });
  }

  getBodyBoundingRect() {
    return this.getOrPutInCache('bodyBoundingRect', () => {
      return (
        this.calcBodyBoundingRect() || {
          x: 0,
          y: 0,
          width: window.innerWidth,
          height: window.innerHeight,
        }
      );
    });
  }

  calcBodyBoundingRect() {
    if (utils.isVerbose()) {
      console.count('calcBodyBoundingRect');
    }
    try {
      const popupRootElement = window.document.getElementById('POPUPS_ROOT');
      if (!this.galleryWrapperElement) {
        this.galleryWrapperElement = window.document.getElementById(
          `gallery-wrapper-${this.domId}`,
        );
      }
      if (
        popupRootElement &&
        this.galleryWrapperElement &&
        popupRootElement.contains(this.galleryWrapperElement)
      ) {
        return popupRootElement.getBoundingClientRect();
      } else {
        return window.document.body.getBoundingClientRect();
      }
    } catch {
      return false;
    }
  }

  calcScrollBase(newWrapperBoundingRectY) {
    return this.getOrPutInCache('scrollBase', () => {
      let { scrollBase } = this.container;
      try {
        if (!(scrollBase >= 0)) {
          scrollBase = 0;
        }
        this.wrapperBoundingRectY =
          newWrapperBoundingRectY === undefined
            ? this.getBoundingRect().y
            : newWrapperBoundingRectY;
        const offset = this.wrapperBoundingRectY - this.getBodyBoundingRect().y; // clientRect are relative to the viewport, thus affected by scroll and need to be normalized to the body
        if (offset >= 0) {
          scrollBase += offset;
        }
      } catch (e) {
        //
      }
      return Math.ceil(scrollBase);
    });
  }

  getGalleryWidth() {
    return this.getOrPutInCache('galleryWidth', () => {
      const domWidth = () =>
        utils.isSSR() ? '' : Math.floor(this.getBoundingRect().width);
      return this.container.width > 0
        ? Math.floor(this.container.width)
        : domWidth();
    });
  }

  getGalleryHeight() {
    return this.getOrPutInCache('galleryHeight', () => {
      const domHeight = () =>
        utils.isSSR() ? '' : Math.floor(this.getBoundingRect().height);
      return this.container.height > 0
        ? Math.floor(this.container.height)
        : domHeight();
    });
  }
}
